.blogHeroSectionContainer {
    //background-color: #000;
    background-color: rgba(52, 58, 64, 0.9);;
    overflow: hidden;
    padding-block: 100px;
    padding-inline: 90px;

    // py-[100px] lg:py-[60px] md:py-[60px] sm:py-[50px] px-[90px] lg:px-[40px] xs:px-0

    .left-col {
        margin-bottom: 10px;
        padding-block: 10px;
        padding-inline: 15px;
        // lg:mb-[10px] py-[10px] px-[10px] font-serif xs:px-[15px] p-[10px] lg:px-[25px] sm:px-[15px]
    }


}


.blog-box {
    border-radius: 9px;
    overflow: hidden;
    .blog-img {
        height: 200px;
        object-fit: cover;
    }
    .blog-thumb-img {
        position: relative;
        overflow: hidden;
        z-index: 0;

        .bg-overlay {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.65);
            opacity: 0;
            transition: all 0.5s ease;
        }
    
        .blog-link {
            top: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
    
            .hover-link {
                opacity: 0;
                bottom: -25px;
                transition: 0.5s ease;
				color: $white;
				font-size: 32px;
            }
        }
    }
    
    &:hover {
        .blog-thumb-img {
            .blog-link {
                .hover-link {
                    opacity: 1;
                    bottom: 0;
                }
            }
    
            .bg-overlay {
                opacity: 1;
            }
        }
    }
}

.blogPageWrapper {
    margin-top: 1.4rem;
    .blog-time-author {
        display: flex;
        margin-bottom: 2rem;
    
        @media(max-width: 550px){
            flex-direction: column;
         }
    
         .blog-time-author-item{
            display: inline-block;
            margin-right: 25px;
            vertical-align: middle;
         }
    }
    
    .blogPostTitle {
        color: #232323;
        font-weight: 500;
        margin-bottom: 4.5rem;
        font-size: 2.3rem;
        line-height: 3rem;
    }
    
    .blogMainImage{
        width: 100%;
        border-radius: 6px;
        margin-bottom: 4.5rem;
    }

    .content {
        margin-bottom: 25px;
        font-size: 18px;
    }

  
}

.relatedBlogPosts {
    // bg-lightgray py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden
    overflow: hidden;
    padding-block: 130px;
    background-color: rgb(247 247 247);
    padding-inline: 75px;
    // paddingBlock: '130px', paddingInline: '75px'

    @media(max-width: 768px){
        padding-block: 75px;
     }
     @media(max-width: 450px){
        padding-block: 130px;
     }
}

.sidebar {
    // col-12 col-xl-3 offset-xl-1 col-lg-4 col-md-7 md:pl-[15px]
    @media(min-width: 992px){
        width: 33%;
        flex: 0 0 auto;
     }
    @media(min-width: 768px){
        padding-left: 15px;
        width: 100%;
     }
}

