.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 80px;
    width: 80px;
    background-color: rgba(69, 203, 133, 0.15);


    .imgContainer {
        background-color: rgba(69, 203, 133); 
        border-radius: 50px;
        height: 55px;
        width: 55px;
        display: flex;
        justify-content: center;
    align-items: center;
    }
}