@import "_variables";

.hero-btn {
  background-color: red;
  padding: 5px 30px;
  background-color: transparent;
  transition: all 0.3s linear;
  color: white;
  border: 2px solid white;
  border-radius: 20px;

  &:hover {
    background-color: white;
    color: $primary;
  }
}

.services-container {
  margin: 20px 0px;
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 90%;
    margin: auto;

    .service {
      display: flex;
      gap: 10px;
      width: 48%;
      font-size: 12px;

      h3 {
        font-size: 16px;
        // font-weight: 600;
        // color: $primary;
        // color: #00A7DE;
        margin: 0;
        background-image: linear-gradient(to left, #0060E0, #00a7de);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .image-section {
        width: 40%;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
        }
      }

      .text-section {
        width: 50%;

        button {
          padding: 5px 10px;
          font-size: 10px;
          background-color: transparent;
          border: 1px solid black;
          border-radius: 20px;
          transition: all 0.3s ease;
          font-weight: 500;

          &:hover {
            background-color: $primary;
            color: white;
            border: 1px solid white;
          }
        }
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

@media (max-width: "630px") {
  .swiper-slide {
    width: 200px !important;
  }
  .services-container {
    .content {
      flex-direction: column-reverse;

      .service {
        width: 97%;
        padding: 10px;
        margin: auto;
        flex-direction: column;
        // border-top: 1px solid grey;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1),
          0px -4px 10px rgba(0, 0, 0, 0.1);

        .image-section {
          width: 80%;
          margin: auto;
        }
        .text-section {
          width: 100%;
        }
      }
    }
  }
}

