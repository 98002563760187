/* slider navigation style 04 */
.blogs-swiper-navigation {
    .swiper-button-next,
    .swiper-button-prev {
      margin: 0;
      transform: translateY(-50%);
      background-color: var(--swiper-navigation-background);
      color: var(--swiper-navigation-color);
      transition: var(--default-transition);
      box-shadow: 0 0 35px rgb(40, 42, 54, 0.18);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
  
      &:hover {
        background-color: var(--swiper-navigation-color);
        color: var(--swiper-navigation-background);
      }
    }
  }

  .blogHeroSwiper {
    height: 500px;
    position: relative;
    width: 100%;
    // border: 1px solid red;

    @media(max-width: 1199px){
        height: 100%;
    }
    @media(max-width: 768px){
        height: 300px;
    }


    .blogHeroSlide{
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        height: 100%;
        // border: 1px solid red;

        .swiperSlideItem{
            display: flex;
            align-items: center;
            background-color: #000000b3;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding-block: 40px;
            padding-left: 30px;
            padding-right: 50px;
            gap: 15px;
    
            // @media(max-width: 1199px){
            //     height: 100%;
            // }
            @media(max-width: 768px){
                padding-block: 25px;
            }
            @media(max-width: 450px){
                align-items: start;
                padding-right: 30px;
                padding-left: 20px;
                flex-direction: column;
                padding-block: 30px;
            }
    
            .title {
                text-transform: uppercase;
                border-color: #ffffff33;
                color: #c89965;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 2px;
    
                &:hover{
                    color: #fff;
                }

               
                @media(max-width: 768px){
                    border: none;
                    margin-bottom: 10px;
                }
                @media(max-width: 450px){
                   margin-bottom: 5px;
                }
                // uppercase ps-0 pr-8 mr-8 border-r border-[#ffffff33] text-[#c89965] tracking-[2px] text-md font-medium tracking-2px font-serif md:border-0 md:mb-[10px] hover:text-white xs:mb-[5px]
            }
            .line{
                width: 20px;
                background-color: #e5e7eb;
                height: 100%;
                border: 2px solid rgb(229, 231, 235);
            }

            .subTitle {
                transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
                color: #fff;

                &:hover {
                    color: #c89965;
                }
            }

           
        }

        
    }

    .swiperBtnWrapper{

        height: 140px;
        position: absolute;
        width: 100%;
        bottom: 0;
        // border: 4px solid red;
        // h-[140px] absolute w-full bottom-0 md:h-[110px] xs:h-[115px]

        @media(max-width: 768px){
            height: 110px;
        }
        @media(max-width: 450px){
            height: 115px;
        }

        .right-arrow {
            background-color: #000;
            position: absolute;
            color: #fff;
            border: none;
            right: 0;
            top: 0;
            z-index: 1;
            height: 50%;
            font-size: 20px;
            width: 50px;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
        }

        .left-arrow {
            background-color: #000;
            position: absolute;
            color: #fff;
            border: none;
            right: 0;
            bottom: 0;
            z-index: 1;
            height: 50%;
            font-size: 20px;
            width: 50px;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            // bg-black text-white absolute bottom-0 border-0 right-0 text-[20px] w-[50px] flex h-1/2 text-center justify-center items-center z-[1]
        }
    }
   

    // flex items-center bg-[#000000b3] absolute left-0 bottom-0 w-full py-[55px] xl:py-[20px] lg:py-[55px] md:py-[40px] xs:py-[30px] px-[60px] xl:px-[50px] xs:pl-[30px] xs:pr-[50px] xs:flex-col xs:items-start
  }