// features


.features-bg {
    background-image: url("../images/features-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.features-bg-svg {
    background-image: url("../images/icon/features-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}