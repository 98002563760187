

/* Theme Name: Brentsoft - Responsive bootsrap 5 Landing Page Template
   Author: Coderthemes
   Version: 1.2.0
   Created: Nov 2018
   File Description: Main Css file of the template
*/

/************************
01.General
02.Loader
03.Helper
04.Components
05.Menu
06.Footer
07.Multipurpose Layout
08.Contact
09.Agency Layout
10.Startup layout
11.Business Layout
12.Software Layout
12.App Layout
13.Default
************************/

//Fonts
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";
@import "fonts";

@import "variables";
@import "utilities";
@import "general";
@import "avatar";
@import "helper";
@import "components";
@import "menu";
@import "footer";
@import "contact";
@import "app-layout";
@import "heros";
@import "how-it-work";
@import "features";
@import "cta";
@import "counter";
@import "preview";
@import "popover";
@import "swiper";
@import "blog";

@import "screenshot";
@import "pricing";
@import "faqs";
@import "footer-cta";
@import "alert";

.blur {
   filter: blur(5px);
}
