.footer-cta {
    background-color: rgba(31, 97, 235, 0.2);
    border-radius: 12px;
    padding: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
        width: 55%;
        font-size: clamp(18px, 2cqi, 26px);
        font-weight: 700;
    }

    .linkWrapper {
        width: 35%;
        display: flex;
        justify-self: flex-end;
        // border: 1px solid red;
    }
}


.footer-cta-1{
    background-color: #F1F2F3;
    border-radius: 12px;
    padding: 45px;
    box-shadow: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
        width: 55%;
        font-size: clamp(18px, 2cqi, 26px);
        font-weight: 700;
    }

    .linkWrapper {
        width: 35%;
        display: flex;
        justify-self: flex-end;
        // border: 1px solid red;
    }
}

.website-development{
    background-color: #c4cbe5;
    border-radius: 12px;
    padding: 45px;
    box-shadow: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
        width: 55%;
        font-size: clamp(18px, 2cqi, 26px);
        font-weight: 700;
    }

    .linkWrapper {
        width: 35%;
        display: flex;
        justify-self: flex-end;
        // border: 1px solid red;
    }
}