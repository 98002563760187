.popover-body {
    padding: 20px 0;
}

.user-popover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    border-color: rgb(226 232 240 / 1);
    
    .popover-link{
        // background: #f1f5f9;
        // border: 1px solid red;
        padding: 2px 10px;
        &:hover {
            background: #f1f5f9
        }
    }

    .popover-btn {
        padding: 2px 10px;
        background: #fff;
        &:hover {
            background: #f1f5f9
        }
    }
  }